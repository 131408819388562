<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy vositalar qayta baholanishi</h3>
          </div>
        </div>

        <div class="card-body">
          <div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="getMainAssetsRemarks"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.action`]="{ item }">
                <Action :index="item"></Action>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'
export default {
  data() {
    return {
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Turi',
          value: 'type_name'
        },
        {
          text: 'Baholanish qiymati',
          value: 'remark_value'
        },
        {
          text: 'Sana #',
          value: 'oper_date'
        },
        {
          text: 'Kommet',
          value: 'comment'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('mainAssetsRemarks')
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getMainAssetsRemarks() {
      const data = this.$store.state.requests.mainAssetsRemarks
      data.forEach((element) => {
        // if (element.remark_value !== null) {
        //   element.remark_value = element.remark_value.toLocaleString("es-US");
        // }
        if (element.type !== undefined && element.type == 'DEC') {
          element.type_name = 'Pasaytirish'
        } else {
          element.type_name = 'Oshirish'
        }
      })
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Asosiy vositalar qayta baholanishi' }
    ])
  },
  components: {
    Action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
