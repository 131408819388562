<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <!-- <router-link :to="'/details/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text ">{{ $t('ACTION.DETAILS') }} </span>
        </router-link> -->
        <!-- <router-link :to="'/update/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text ">{{ $t('ACTION.UPDATE') }} </span>
        </router-link> -->
        <div class="navi-link" id="show-btn" @click="dialog = true">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </div>
        <div>
          <div class="navi-link" id="show-btn" @click="showModal">
            <span class="navi-icon">
              <i class="flaticon-close text-danger"></i>
            </span>
            <span class="navi-text">O’chirish</span>
          </div>
          <b-modal ref="my-modal" hide-footer title="O’chirish">
            <div class="d-block text-center">
              <h3>O’chirish</h3>
            </div>
            <b-button
              class="mt-3"
              variant="outline-warning"
              block
              @click="hideModal"
              >Bekor qilish</b-button
            >
            <b-button
              class="mt-2"
              variant="outline-danger"
              block
              @click="deleteNom(index.id)"
              >O’chirish</b-button
            >
          </b-modal>
        </div>
      </b-dropdown-text>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Asosiy vositalar qayta baholanishi</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="index.type"
                        :items="[
                          { text: 'Oshirish', value: 'INC' },
                          { text: 'Pasaytirish', value: 'DEC' }
                        ]"
                        label="Turi"
                        item-text="text"
                        item-value="value"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="index.remark_value"
                        v-currency="options"
                        label="Summasi"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="index.oper_date"
                            label="Picker without buttons"
                            prepend-inner-icon="event"
                            readonly
                            outlined
                            v-bind="attrs"
                            dense
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="index.oper_date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="index.comment"
                        outlined
                        label="Komment"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false">Bekor qilish</v-btn>
                <v-btn color="primary" @click="save">Saqlash</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
  </b-dropdown>
</template>

<script>
// import { mapActions } from "vuex";
export default {
  data() {
    return {
      id: '',
      dialog: false,
      menu2: false,
      date: '',
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false
    }
  },
  name: 'nomenklaturaAction',
  props: {
    index: Object,
    tabid: Number,
    testidd: Number
  },
  computed: {
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    save() {
      const data = {
        type: this.index.type,
        remark_value: parseInt(
          this.index.remark_value.replace(/[ ,.]/g, ''),
          10
        ),
        oper_date: this.index.oper_date,
        comment: this.index.comment
      }
      this.$store.dispatch('mainAssetsRemarksUpdate', {
        id: this.index.id,
        data: data
      })
      this.dialog = false
    },
    // submitUpdate() {},
    // ...mapActions(["deleteNomenklatura"]),

    // ...mapActions({ confirmDelete: "deleteNomenklatura" }),
    deleteNom(value) {
      const data = {
        id: value
      }
      this.$store.dispatch('mainAssetsRemarksDelete', data)
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_submit {
  background-color: #007bff;
  color: rgb(255, 255, 255) !important;
}
.headline__text {
  font-size: 1rem !important;
  color: #000;
  font-weight: bold;

  text-decoration: underline;
}
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
<style scoped>
.navi /deep/ .navi-item /deep/ .navi-link {
  padding: 0.75rem 1rem !important;
  cursor: pointer;
}
</style>
